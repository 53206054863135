<template>
  <div class="careers-page">
    <h1>Careers</h1>
    <div class="job-listings">
      <h2>Current Openings</h2>
      <div class="job" v-for="(job, index) in jobList" :key="index">
        <div class="job-info">
          <h3>{{ job.title }}</h3>
          <p>Location: {{ job.location }}</p>
          <p>Experience: {{ job.experience }}</p>
          <button @click="applyNow(job.title)">Apply Now</button>
        </div>
        <div class="job-image">
          <img :src="job.image" :alt="job.title">
        </div>
      </div>
    </div>
    <div class="submit-cv">
      <button @click="toggleCVForm">Submit Your CV</button>
      <!-- Formspree Integration -->
      <form v-if="showCVForm" action="https://formspree.io/f/mwpegaod" method="POST" @submit.prevent="submitCV" enctype="multipart/form-data">
        <!-- Hidden field for form name (if required by Formspree) -->
        <input type="hidden" name="form-name" value="cv-submission" />

        <!-- Name Field -->
        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" id="name" name="name" v-model="candidate.name" required>
        </div>

        <!-- Mobile Field -->
        <div class="form-group">
          <label for="mobile">Mobile:</label>
          <input type="tel" id="mobile" name="mobile" v-model="candidate.mobile" required>
        </div>

        <!-- Email Field -->
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" name="email" v-model="candidate.email" required>
        </div>

        <!-- Personal Image Field -->
        <div class="form-group">
          <label for="personal-image">Personal Image:</label>
          <input type="file" id="personal-image" @change="handleImageUpload" name="personal-image" accept="image/*" required>
        </div>

        <!-- CV Upload Field -->
        <div class="form-group">
          <label for="cv">Upload CV:</label>
          <input type="file" id="cv" @change="handleFileUpload" name="cv" accept=".pdf,.doc,.docx" required>
        </div>

        <!-- Job Desire Dropdown -->
        <div class="form-group">
          <label for="job-desire">Job Desire:</label>
          <select id="job-desire" name="job-desire" v-model="candidate.jobDesire" required>
            <option v-for="job in jobList" :value="job.title" :key="job.title">{{ job.title }}</option>
          </select>
        </div>

        <!-- Submit Button -->
        <button type="submit">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CareersPage',
  data() {
    return {
      jobList: [
        {
          title: 'Senior Petroleum Engineer',
          location: 'Baghdad, Iraq',
          experience: '5+ years in the oil and gas industry',
          image: require('@/assets/petroleum_engineer.jpg')
        },
        {
          title: 'HR Specialist',
          location: 'Basra, Iraq',
          experience: '3+ years in human resources',
          image: require('@/assets/hr_specialist.jpg')
        }
        // More jobs can be added here
      ],
      candidate: {
        name: '',
        mobile: '',
        email: '',
        personalImage: null,
        cv: null,
        jobDesire: ''
      },
      showCVForm: false
    };
  },
  methods: {
    handleFileUpload(event) {
      this.candidate.cv = event.target.files[0];
    },
    handleImageUpload(event) {
      this.candidate.personalImage = event.target.files[0];
    },
    async submitCV() {
      // You can optionally handle any additional logic here if needed, like form validation
      alert("Your CV has been submitted successfully!");
    },
    applyNow(jobTitle) {
      this.showCVForm = true;
      this.candidate.jobDesire = jobTitle;
      this.$nextTick(() => {
        const formElement = document.querySelector('.submit-cv');
        if (formElement) {
          formElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    toggleCVForm() {
      this.showCVForm = !this.showCVForm;
    }
  }
};
</script>

<style scoped>
/* Your styles remain unchanged */
</style>
